@import "./src/utils/sass/main";

.btn {
  border: none;
  text-transform: capitalize;
  color: var(--color-white);
  background-color: var(--color-green);
  &:hover {
    cursor: pointer;
    color: var(--color-green);
    background-color: var(--color-white);
    transition: 300ms;
    border: 2px solid var(--color-green);
  }
  &--small {
    width: 120px;
    height: 30px;
    @include text-bold-07;
  }
  &--medium {
    width: 180px;
    height: 35px;
    @include text-bold-06;
  }
  &--big {
    width: 220px;
    height: 45px;
    @include text-bold-06;
  }
  &--border {
    border: 2px solid var(--color-white);
  }
  &--subs {
    width: auto;
    padding: 0 10px;
  }
}

@media screen and (min-width: 768px) {
  .btn {
    &--small {
      width: 180px;
      height: 35px;
      @include text-bold-06;
    }
    &--medium {
      width: 220px;
      height: 45px;
      @include text-bold-06;
    }
    &--subs {
      padding: 0 20px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .btn {
    &--subs {
      height: 45px;
    }
  }
}
