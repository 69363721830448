@import "./src/utils/sass/main";

.container__menu {
  background-color: white;
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 1;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  position: fixed;
  right: 0;
  background-color: white;
}

.menu__ul {
  z-index: 1;
  position: fixed;
  top: 70px;
  right: -200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 70vh;
  max-height: 600px;
  padding: 0;
  margin: 0;
  list-style: none;
  border-left: 10px solid var(--color-green);
  background-color: var(--color-white);
  color: var(--color-grey);
  @include text-01;
  text-transform: capitalize;
  transition: 1000ms;
  &--open {
    display: flex;
    right: 0;
    transition: 500ms;
  }
  &__li {
    cursor: pointer;
    transition: 0.2s;
    &--active {
      color: var(--color-black);
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 768px) {
  .container__menu {
    height: 100px;
  }
  .menu__ul {
    top: 100px;
    right: -310px;
    width: 310px;
    @include text-bold-05;
    font-weight: 400;
    &--open {
      right: 0;
      transition: 800ms;
    }
  }
}

@media screen and (min-width: 1200px) {
  .container__menu {
    height: 80px;
  }
  .menu {
    padding: 0;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    position: fixed;
    top: 0;
    left: 50px;
    transform: translate(-50px);
  }

  .menu__ul {
    position: static;
    flex-direction: row;
    width: 650px;
    height: auto;
    border: none;
    padding-left: 100px;
    @include text-01;
  }
}
