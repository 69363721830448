@import "./src/utils/sass/main";
.section_2 {
  padding: 40px 20px 0;
  width: calc(100% - 40px);
  margin: 0 auto;
  & h2 {
    padding: 0 0 20px;
    text-align: center;
    @include text-bold-05;
  }
}

@media screen and (min-width: 768px) {
  .section_2 {
    padding: 50px 40px 0;
    width: calc(100% - 80px);
    margin: 0 auto;
    & h2 {
      padding: 0 0 20px;
      text-align: center;
      @include text-bold-05;
    }
  }
}

@media screen and (min-width: 1300px) {
  .section_2 {
    padding: 100px 40px 10px;
    width: 1200px;
    & h2 {
      padding: 20px 0;
      @include text-bold-02;
    }
  }
}
