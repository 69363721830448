@import "src/utils/sass/main";
.section_4 {
  padding: 0 20px;
  & h2 {
    @include text-bold-05;
    padding-bottom: 10px;
  }
  & p {
    @include text-02;
    padding: 10px 0;
    line-height: 20px;
  }
  &__blocks {
    display: flex;
    overflow-x: auto;
    padding: 10px 0 20px;
  }
}

@media screen and (min-width: 768px) {
  .section_4 {
    padding: 0 40px;
    & p {
      @include text-01;
      line-height: 24px;
    }
    &__blocks {
      display: flex;
      justify-content: space-between;
      overflow-x: hidden;
      padding: 10px 0 20px;
    }
  }
}

@media screen and (min-width: 1300px) {
  .section_4 {
    padding: 0;
    margin: 0 auto 70px;
    width: 1200px;
    & h2 {
      @include text-bold-02;
      padding: 30px 0 20px;
    }
    & p {
      @include text-01;
      line-height: 24px;
    }
    &__blocks {
      display: flex;
      justify-content: space-between;
      overflow-x: hidden;
      padding: 10px 0 20px;
    }
  }
}
