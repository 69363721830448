@import "./src/utils/sass/main";

.accordion {
  padding: 10px 0;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  &__info {
    padding: 10px 0;
    &__id {
      @include text-03;
      padding-bottom: 5px;
    }
    &__title {
      @include text-bold-06;
    }
  }
  &__content {
    @include text-03;
    line-height: 18px;
    padding: 5px 0;
    width: 100%;
    position: absolute;
    top: 100%;
    opacity: 0;
    &--closed {
    }
    &--opened {
      position: static;
      opacity: 1;
      transition: 0.5s;
    }
  }
  & hr {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .accordion {
    padding: 20px 0;
    &__info {
      &__id {
        @include text-02;
        padding-bottom: 10px;
      }
      &__title {
        @include text-bold-05;
      }
    }
    &__content {
      @include text-02;
      line-height: 24px;
      padding: 10px 0;
    }
  }
}

@media screen and (min-width: 1300px) {
  .accordion {
    padding: 0;
    &__info {
      &__id {
        @include text-01;
        padding: 10px 0 10px;
      }
      &__title {
        padding: 10px 0 20px;
        @include text-bold-04;
      }
    }
    &__content {
      @include text-01;
      line-height: 32px;
      padding: 0 0 20px;
    }
  }
}
