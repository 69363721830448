@mixin bold {
  cursor: default;
  font-weight: 700;
  margin: 0;
}

@mixin regular {
  cursor: default;
  font-weight: 400;
  margin: 0;
}

@mixin text-bold-01 {
  font-size: 48px;
  @include bold;
}

@mixin text-bold-02 {
  font-size: 40px;
  @include bold;
}

@mixin text-bold-03 {
  font-size: 34px;
  @include bold;
}

@mixin text-bold-04 {
  font-size: 28px;
  @include bold;
}

@mixin text-bold-05 {
  font-size: 20px;
  @include bold;
}

@mixin text-bold-06 {
  font-size: 16px;
  @include bold;
}

@mixin text-bold-07 {
  font-size: 14px;
  @include bold;
}

@mixin text-01 {
  font-size: 16px;
  @include regular;
}

@mixin text-02 {
  font-size: 14px;
  @include regular;
}

@mixin text-03 {
  font-size: 12px;
  @include regular;
}

@mixin text-04 {
  font-size: 10px;
  @include regular;
}