@import "src/utils/sass/main";

.closed {
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  opacity: 1;
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.circle-plus .circle {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: solid 3px var(--color-green);
  @include shake-animation;
}
.circle-plus .circle .horizontal {
  position: absolute;
  background-color: var(--color-green);
  width: 15px;
  height: 3px;
  left: 50%;
  margin-left: -7.5px;
  top: 50%;
  margin-top: -1.5px;
}

.circle-plus .circle .vertical {
  position: absolute;
  background-color: var(--color-green);
  width: 3px;
  height: 15px;
  left: 50%;
  margin-left: -1.5px;
  top: 50%;
  margin-top: -7.5px;
}

.circle-plus:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media screen and (min-width: 1300px) {
  .circle-plus .circle {
    width: 45px;
    height: 45px;
    border: solid 4px var(--color-green);
  }
  .circle-plus .circle .horizontal {
    width: 20px;
    height: 4px;
    margin-left: -10px;
    margin-top: -2px;
  }

  .circle-plus .circle .vertical {
    width: 4px;
    height: 20px;
    margin-left: -2px;
    margin-top: -10px;
  }
}
