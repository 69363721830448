@import "./src/utils/sass/main";

.section_7 {
  background-color: var(--color-green);
  color: var(--color-white);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  height: 160px;
  & h3 {
    @include text-bold-06;
  }
  & div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & input {
      height: 30px;
      border: none;
      @include text-04;
      width: 50%;
      max-width: 500px;
      margin-right: 10px;
      padding: 0 0 0 10px;
      &:hover {
        cursor: text;
      }
    }
    & input::placeholder {
      color: var(--color-green);
    }
  }
  & p {
    @include text-04;
  }
}

@media screen and (min-width: 768px) {
  .section_7 {
    & h3 {
      @include text-bold-05;
    }
    & div {
      & input {
        height: 35px;
        @include text-03;
        width: 40%;
        margin-right: 10px;
      }
    }
    & p {
      @include text-03;
    }
  }
}

@media screen and (min-width: 1200px) {
  .section_7 {
    height: 210px;

    & h3 {
      @include text-bold-04;
    }
    & div {
      & input {
        height: 45px;
        @include text-02;
        width: 30%;
        margin-right: 10px;
      }
    }
    & p {
      @include text-01;
    }
  }
}
